/*
// Latest Dev Server URL 3.13.225.187
*/
// export const apiUrl = "http://3.13.225.187:9093/misadmin-service/api";
//Prod
// export const apiUrl = "https://admin.mis-live.com/misadmin-service/api";
// export const SOCKET_URL = "https://admin.mis-live.com/mis-websocket";
//Stag
// export const apiUrl = "https://misadminstag.techment.com/misadmin-service/api";
// export const SOCKET_URL = "https://misadminstag.techment.com/mis-websocket";
//Qa
export const apiUrl = "https://misadminqa.techment.com/misadmin-service/api";
export const SOCKET_URL = "https://misadminqa.techment.com/mis-websocket";
//Dev
// export const apiUrl = "https://misadmindev.techment.com/misadmin-service/api";
// // export const apiUrl = "http://localhost:9093/misadmin-service/api";
// export const SOCKET_URL = "https://misadmindev.techment.com/mis-websocket";

//constants
export const ErrorMsg = "Something went wrong. Please try again";
export const TokenError = "Something went wrong. Please try again";
export const ReqField = "Required Field";
export const monthJson = [
  { index: 1, name: "January", short: "Jan", maxvalue: 31 },
  { index: 2, name: "February", short: "Feb", maxvalue: 28 },
  { index: 3, name: "March", short: "Mar", maxvalue: 31 },
  { index: 4, name: "April", short: "Apr", maxvalue: 30 },
  { index: 5, name: "May", short: "May", maxvalue: 31 },
  { index: 6, name: "June", short: "June", maxvalue: 30 },
  { index: 7, name: "July", short: "July", maxvalue: 31 },
  { index: 8, name: "August", short: "Aug", maxvalue: 31 },
  { index: 9, name: "September", short: "Sept", maxvalue: 30 },
  { index: 10, name: "October", short: "Oct", maxvalue: 31 },
  { index: 11, name: "November", short: "Nov", maxvalue: 30 },
  { index: 12, name: "December", short: "Dec", maxvalue: 31 },
];
export const daysJson = [
  { index: 1, day: "mon", name: "Monday", short: "Mon" },
  { index: 2, day: "tue", name: "Tuesday", short: "Tue" },
  { index: 3, day: "wed", name: "Wednesday", short: "Wed" },
  { index: 4, day: "thu", name: "Thursday", short: "Thu" },
  { index: 5, day: "fri", name: "Friday", short: "Fri" },
  { index: 6, day: "sat", name: "Saturday", short: "Sat" },
  { index: 7, day: "sun", name: "Sunday", short: "Sun" },
];
